.statistic-block {
    display: flex;
    gap: var(--space-large);
}

.statistic-block-item {
    flex-grow: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
}

.statistics td, th {
    padding: 5px;
    border: 1px solid black;
}

.statistics th {
    text-align: left;
}

.statistics td {
    text-align: right;
}

.statistics table {
    width: 100%;
    border-collapse: collapse;
    border: 2px solid black;
}

.button-container{
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: var(--space-small);
}

.theme {
    text-align: center;
}