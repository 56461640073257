:root {
  --main-bg-color: white;
  --inner-corner-color: white;
  --empty-tile-color: lightgrey;
  --snake-color: rgb(97, 146, 101);
  --snake-hover-color: rgba(97, 146, 101, 0.438);
  --secondary-color: white;
  --secondary-color-dark: rgb(226, 226, 226);
  --secondary-text-color: black;
  --info-text-color: rgb(150, 150, 150);
  --snake-letter-color: white;
  --empty-letter-color: black;
  --hint-color: rgba(97, 146, 101, 0.438);
  --hint-color-wrong: rgba(146, 97, 97, 0.438);
  
  --tile-color-1: rgb(242, 192, 192);
  --tile-color-2: rgb(216, 210, 157);
  --tile-color-3: rgb(159, 198, 218);

  /* Padding and spacing */
  --space-small: 8px;
  --space: 16px;
  --space-large: 24px;
  --space-xlarge: 32px;
}

.App {
  text-align: center;
  color: rgb(0, 0, 0);
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  min-height: 100dvh;
}

.nav-header{
  width: 100%;
}

.modal{
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 40px !important;
  .MuiBox-root{
    padding: var(--space-large);
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    width: 380px;
    max-width: 80%;
    box-shadow: 24px;
    background-color: var(--main-bg-color);
    outline: none
  }
}

.MuiButton-root.MuiButton-outlinedPrimary {
  text-transform: none;
  background-color: var(--secondary-color);
  color: black;
  border-color: black;
}

.MuiButton-root.MuiButton-outlinedPrimary:hover {
  background-color: var(--secondary-color-dark);
  border-color: black;
}

.info-text-container {
  color: var(--info-text-color);
  font-style: italic;
}

.demo-text{
  padding: var(--space) var(--space) 0 var(--space);
  max-width: 800px;
}

/* SPACING */

.padding-bottom-none {
  padding-bottom: 0;
}

.padding {
  padding: var(--space)
}

.padding-bottom {
  padding-bottom: var(--space)
}

.padding-top {
  padding-top: var(--space)
}

.padding-small {
  padding: var(--space-small);
}

.padding-large {
  padding: var(--space-large);
}

.padding-xlarge {
  padding: var(--space-xlarge);
}

.modal-image{
  max-width: 280px;
  padding-top: var(--space-large);
  margin: var(--space-large);
}

.page-body{
  flex-grow: 1;
}

.page-footer{
  flex-grow: 0;
  flex-shrink: 0;
}

.page-footer a {
  display: flex;
  align-items: center;
  gap: var(--space-small);
  color: var(--info-text-color);
  text-decoration: none;
}

.page-footer a:hover {
  text-decoration: underline;
}